import React, { useState, useEffect } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import { useMutation } from "react-query";
import { Keys } from "constants/Keys";
import { postConsent } from "api";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#2E323C",
    borderRadius: "8px 8px 0 0",
    bottom: "0",
    left: "50%",
    maxWidth: "985px",
    padding: "30px 20px",
    position: "fixed",
    transform: "translateX(-50%)",
    zIndex: 3,
    width: "100%"
  },
  mainContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  informative: {
    color: "#fff",
    font: "14px Poppins",
    marginBottom: "20px"
  },
  link: {
    color: "#fff",
    fontWeight: 600,
    textDecoration: "underline"
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    font: "16px Poppins",
    fontWeight: 600,
    height: "55px",
    marginLeft: "0",
    textTransform: "initial",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  [theme.breakpoints.up("md")]: {
    root: {
      borderRadius: "8px",
      bottom: "30px",
      padding: "25px 60px",
      width: "100%"
    },
    mainContent: {
      flexDirection: "row"
    },
    informative: {
      fontSize: "14px",
      letterSpacing: "0",
      marginBottom: "0"
    },
    button: {
      height: "45px",
      letterSpacing: "0",
      marginLeft: "25px",
      maxWidth: "130px",
      padding: "12px 16px",
      width: "100%"
    }
  }
}));

const PolicyCookies = () => {
  const classes = useStyles();
  const [isPolicyAccepted, setConfirmation] = useState(true);
  const policyConsent = useMutation(Keys.postConsent(), postConsent, {
    onSuccess: () => {
      setConfirmation(true);
      localStorage.setItem("loopcarros-consent-policy", new Date());
    }
  });
  let hostname = "";

  if (typeof window !== "undefined") {
    hostname = window.location.hostname;
  }
  const payload = { origin: hostname };

  useEffect(() => {
    const storage = localStorage.getItem("loopcarros-consent-policy");

    if (!storage) {
      setConfirmation(false);
    }
  }, []);

  const styleDisplay = isPolicyAccepted ? "none" : "block";

  return (
    <div className={classes.root} style={{ display: styleDisplay }}>
      <div className={classes.mainContent}>
        <Typography component="p" className={classes.informative}>
          Os cookies nos permitem melhorar nossos serviços e recomendar
          conteúdos do seu interesse. Ao prosseguir, você concorda com o uso dos
          cookies. Para saber mais, acessa a{" "}
          <a
            href="https://loop.octadesk.com/kb/article/politica-de-privacidade"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Política de Privacidade
          </a>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => policyConsent.mutate(payload)}
        >
          Ok, entendi!
        </Button>
      </div>
    </div>
  );
};

export default PolicyCookies;
