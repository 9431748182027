import React from "react";
import Head from "next/head";
import HomePage from "features/home/pages/HomePage";

const Home = () => (
  <>
    <Head>
      <title>Loop Veículos</title>
    </Head>
    <HomePage />
  </>
);

export default Home;
