import { Footer, Header } from "components";
import ExclusiveOfferList from "../components/ExclusiveOfferList";
import HowItWorks from "../components/HowItWorks";

const HomePage = () => {
  return (
    <>
      <Header />
      <ExclusiveOfferList />
      <HowItWorks />
      <Footer />
    </>
  );
};

export default HomePage;
