import { Container, Grid, makeStyles } from "@material-ui/core";
import { Subtitle } from "components";
import { useAuth } from "hooks/useAuth";
import { useFeaturedGroups } from "../hooks/useFeaturedGroups";
import ExclusiveOffer from "./ExclusiveOffer";

const useStyles = makeStyles({
  root: {
    marginBottom: "40px"
  }
});

const ExclusiveOfferList = () => {
  const classes = useStyles();
  const { session } = useAuth();
  const featuredGroups = useFeaturedGroups();

  if (
    !featuredGroups.data ||
    !session?.groups?.length ||
    featuredGroups.data.doc_count === 0
  ) {
    return null;
  }

  return (
    <Container className={classes.root}>
      <Subtitle>Ofertas Exclusivas</Subtitle>
      <Grid container direction="row" alignItems="center" spacing={2}>
        {featuredGroups.data.group.buckets.map(({ key }) =>
          session.groups.some(({ label }) => label === key) ? (
            <Grid item md={3} key={key}>
              <ExclusiveOffer name={key} />
            </Grid>
          ) : null
        )}
      </Grid>
    </Container>
  );
};

export default ExclusiveOfferList;
