import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    backgroundColor: "#fff",
    border: "1px solid #E9ECEE",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    height: "400px",
    margin: "0 auto 20px",
    padding: "30px 25px",
    maxWidth: "384px"
  },
  title: {
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "10px",
    marginTop: "25px"
  },
  icon: {
    marginBottom: "30px"
  },
  description: {
    fontSize: "15px",
    letterSpacing: "-0.2px",
    textAlign: "center"
  },
  [theme.breakpoints.up("md")]: {
    content: {
      height: "380px",
      maxHeight: "400px",
      width: "100%"
    },
    title: {
      fontSize: "20px"
    }
  }
}));

const HowItWorksCard = ({ icon, title, description }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.icon}>{icon}</div>
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export default HowItWorksCard;
